import { createTabs } from "@/components/Tabs/models";

export const tabs = createTabs(
  {
    name: "базовые",
    image: {
      alt: "",
      view: "03",
      name: "01",
    },
    subs: [
      {
        name: "Метаданные",
        text:
          "Модуль метаданных необходим для настройки сведений о признаках и свойствах данных." +
          "\n\n" +
          "В нем также устанавливаются характеристики сущностей системы, задаются параметры и атрибутивный состав данных.",
        image: {
          alt: "метаданные",
          view: "03",
          name: "01_01",
          extension: "png",
        },
      },

      {
        name: "Данные",
        text:
          "Модуль предназначен для работы с данными на основе настроенной модели метаданных." +
          "\n\n" +
          "Его функциональные возможности позволяют работать с полями таблиц, " +
          "созданных на основе атрибутного состава метаданных, а также настраивать формы ввода данных.",
        image: {
          alt: "данные",
          view: "03",
          name: "01_02",
          extension: "png",
        },
      },

      {
        name: "НСИ",
        text:
          "Модуль ведения НСИ предназначен для централизованного ведения объектов нормативно-справочной информации." +
          "\n\n" +
          "С помощью набора справочников, словарей, классификаторов, стандартов и регламентов модуль обеспечивает единый вид данных системы.",
        image: {
          alt: "нси",
          view: "03",
          name: "01_03",
          extension: "png",
        },
      },

      {
        name: "ФЛК",
        text:
          "Модуль форматно-логического контроля предназначен для настройки и проверки данных системы " +
          "на соответствие заданным форматам и контрольным значениям.",
        image: {
          alt: "флк",
          view: "03",
          name: "01_04",
          extension: "png",
        },
      },

      {
        name: "Конструктор витрин",
        text:
          "Модуль предоставляет необходимые инструменты для формирования срезов данных под нужды пользователей " +
          "и позволяет создавать и настраивать единую точку доступа к данным системы.",
        image: {
          alt: "конструктор витрин",
          view: "03",
          name: "01_05",
          extension: "png",
        },
      },

      {
        name: "Геоданные",
        text:
          "Модуль геоданных предназначен для формирования и отображения данных непосредственно на картографической подложке." +
          "\n\n" +
          "Модуль предоставляет возможность выгрузки и визуализации геопривязанных данных и " +
          "поддерживает последние форматы мировых стандартов для навигационных систем и систем автономного вождения.",
        image: {
          alt: "геоданные",
          view: "03",
          name: "01_06",
          extension: "png",
        },
      },

      {
        name: "Загрузка данных",
        text:
          "Модуль загрузки данных посредством ETL подготавливает и загружает данные в структуру системы." +
          "\n\n" +
          "Возможности модуля позволяют приводить загруженные данные к единой системе значений и вести аудиторский след данных.",
        image: {
          alt: "загрузка данных",
          view: "03",
          name: "01_07",
          extension: "png",
        },
      },

      {
        name: "Информационное взаимодействие",
        text:
          "Модуль предназначен для объединения различных модулей системы, " +
          "внешних источников данных в единое информационное пространство",
        image: {
          alt: "информационное взаимодействие",
          view: "03",
          name: "01_08",
          extension: "png",
        },
      },

      {
        name: "Менеджер модулей",
        text:
          "Модуль предназначен для централизованного управления модулями системы. " +
          "\n\n" +
          "Позволяет настраивать различные конфигурации, " +
          "подключать или исключать модули из единого информационного пространства.",
        image: {
          alt: "менеджер модулей",
          view: "03",
          name: "01_09",
          extension: "png",
        },
      },

      {
        name: "Менеджер фоновых задач",
        text:
          "Модуль предназначен для настройки фоновых процессов системы. " +
          "\n\n" +
          "Позволяет отслеживать и настраивать " +
          "ресурсоёмкие задачи по формированию отчётов и витрин данных, " +
          "задачи загрузки и обработки данных из внешних источников, в т.ч. СМЭВ, " +
          "регламентные работы, в т.ч. по техническому обслуживанию системы.",
        image: {
          alt: "менеджер фоновых задач",
          view: "03",
          name: "01_10",
          extension: "png",
        },
      },

      {
        name: "Модуль управления правами доступа",
        text:
          "Модуль информационной безопасности позволяет вести работу с правами пользователей." +
          "\n\n" +
          "С помощью него осуществляется настройка и управление доступом к системе.",
        image: {
          alt: "модуль управления правами доступа",
          view: "03",
          name: "01_11",
          extension: "png",
        },
      },

      {
        name: "Логирование",
        text:
          "В данном модуле ведется журналирование процессов системы: " +
          "собираются и хранятся события системы, изменения статусов данных, отчеты о работе системы.",
        image: {
          alt: "логирование",
          view: "03",
          name: "01_12",
          extension: "png",
        },
      },
    ],
    description:
      "Базовый набор состоит из модулей для формирования типовой системы, " +
      "к которой подбираются отраслевые компоненты." +
      "\n\n" +
      "При внедрении решения в действующую систему не обязательно использовать весь базовый набор, " +
      "можно ограничиться только теми модулями, которые расширят возможности системы.",
  },

  {
    name: "дополнительные",
    image: {
      alt: "",
      view: "03",
      name: "02",
    },
    subs: [
      {
        name: "Инструмент анализа (BI)",
        text:
          "Модуль предоставляет собой интерактивный настраиваемый веб-интерфейс для бизнес-анализа, " +
          "включая инструменты для визуализации, исследования, составления отчетов и прогнозирования." +
          "\n\n" +
          "Модуль имеет полный спектр инструментов аналитики, что позволяет детализировать отчеты, " +
          "а также визуализировать и анализировать данные по нескольким измерениям.",
        image: {
          alt: "инструмент анализа bi",
          view: "03",
          name: "02_01",
          extension: "png",
        },
      },

      {
        name: "Прокладчик маршрутов",
        text:
          "Модуль предназначен для расчета пути транспорта с учетом заданных параметров и имеющихся ограничений." +
          "\n\n" +
          "Оптимальность расчета обеспечивается динамическими картами и геоданными автомобильных дорог.",
        image: {
          alt: "прокладчик маршрутов",
          view: "03",
          name: "02_02",
          extension: "png",
        },
      },

      {
        name: "Распознавание документов",
        text:
          "Модуль предназначен для извлечения и обработки данных " +
          "из электронных документов включая текстовые документы, графические схемы, " +
          "стандартизированные документы и бланки строгой отчётности. " +
          "\n\n" +
          "Также доступно наполнение модели данных из распознанных документов.",
        image: {
          alt: "распознавание документов",
          view: "03",
          name: "02_03",
          extension: "png",
        },
      },
    ],
    description:
      "Дополнительные модули решают специфичные отраслевые задачи. " +
      "\n" +
      "Расширяют базовые конфигурации, предлагая новые возможности для действующих и еще разрабатываемых систем." +
      "\n\n" +
      "Если в перечне нет модуля под конкретные функциональные потребности, " +
      "то мы можем его разработать.",
  }
);
